import React, { Component } from "react";

class NavbarTop extends Component {
  render() {
    return (
      <div className="navbar-top">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-sm-5 col-md-6">
              <a href="" className="logo">
                <img src="./images/logo.png" alt="" />
              </a>
            </div>
            <div className="col-sm-7 col-md-6 ">
              <div className="down d-flex justify-content-center">
                <a href="https://play.google.com/store/apps/details?id=com.salally" target="_blank" className="mx-auto">
                  <img src="./images/google.png" alt="" />
                </a>
                {/* <a href="https://play.google.com/store/apps/details?id=com.salally" target="_blank">
                  <img src="./images/apple.png" alt="" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavbarTop;
