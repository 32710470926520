import React, { Component } from "react";
import Slider from "react-slick";

class Testomonialas extends Component {
  render() {

    var settings = {
      dots: false,
      infinite: false,
      speed: 80,
      slidesToShow: 1,
      // rtl: currentDir == "rtl" ? true : false,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      loop: true,
      infinite: true,
    }

    
    let imageReview = ["1.png","2.png","3.png","5.png","6.png","7.png","8.png","9.png","10.png"
    ,"12.png","13.png","14.png","15.png","16.png","17.png","18.png","19.png","20.png",
  ];

    return (
      <div>
        <div className="testeminail" id="ourClients">
          <div className="container">
            <img className="title-img" src="./images/title.svg" alt="" />
            <div className="section-header">
              <h5>اراء العملاء</h5>
            </div>
            <div className="teste-slider">

              <Slider {...settings}>

              {/* <div className="slider-item">
                <div className="teste-card">
                  <img src="./images/tes.jpg" alt="" />
                </div>
              </div> */}

            
                {imageReview.map((imageName, index) => (
                  <div className="slider-item" key={index}>
                    <div className="teste-card">
                      <img src={`./images/${imageName}`} alt="" />
                    </div>
                  </div>
                ))}
             

              </Slider>

      
          
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Testomonialas;
